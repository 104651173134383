<template>
  <div class="m3-flex-col">
    <div class="m3-flex m3-margin-l">
      <span class="md-title-medium">行业划分</span>
      <!--      <span-->
      <!--          class="md-primary md-on-primary-text m3-margin-left-l m3-border-radius-xs m3-padding-horizontal-m m3-padding-vertical-xs m3-view-click">添加一级行业</span>-->
    </div>
    <div class="m3-text-title m3-flex m3-align-items-start m3-width-full"
         :style="{height:'75vh'}">
      <div class="m3-flex-1 m3-height-full md-surface m3-border-radius-m m3-padding-m">
        <div
            class="md-primary md-on-primary-text m3-margin-s m3-padding-s m3-border-radius-s m3-text-center m3-view-click"
            :style="{minWidth:'80px',maxWidth:'150px'}"
            v-on:click="addIndustry(0)">
          新增
        </div>
        <div class="m3-width-full m3-border-bottom-variant"></div>

        <div class="m3-height-90 m3-width-full" :style="{overflow:'scroll',}">
          <div class="m3-width-full m3-margin-vertical-xs m3-padding-s md-on-surface-text md-body-medium"
               :class="firstIndustryId==item.id?'md-surface-variant m3-border-radius-s':''"
               v-for="(item,itemIndex) in firstIndustryList"
               :key="'first'+itemIndex">
            <div class="m3-flex m3-justify-content-between"
                 v-on:click="onFirstClick(item,itemIndex)">
              <span class="m3-flex-1">{{ item.name }}
                <a-spin v-if="item.loading"/>
              </span>
              <span v-if="canEdit"
                    class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                    v-on:click.stop="modifyIndustry(item,itemIndex)">编辑</span>
              <span v-if="canDelete"
                    class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                    v-on:click.stop="deleteIndustry(item.id)">删除</span>
            </div>
          </div>
        </div>
      </div>
      <div class="m3-flex-3 m3-height-full">
        <div v-show="firstIndustryId"
             class="m3-height-full md-surface m3-border-radius-m m3-padding-m m3-margin-left-m">
          <div
              class="md-primary md-on-primary-text m3-margin-s m3-padding-s m3-border-radius-s m3-text-center m3-view-click"
              :style="{minWidth:'80px',maxWidth:'150px'}"
              v-on:click="addIndustry(firstIndustryId)">
            新增
          </div>
          <div class="m3-width-full m3-border-bottom-variant"></div>

          <div class="m3-height-90 m3-width-full" :style="{overflow:'scroll',}">
            <div class="m3-width-full m3-margin-vertical-xs m3-padding-s md-on-surface-text md-body-medium"
                 :class="secondIndustryId==item.id?'md-surface-variant m3-border-radius-s':''"
                 v-for="(item,itemIndex) in secondIndustryList"
                 :key="'second'+itemIndex">
              <div class="m3-flex m3-justify-content-between"
                   v-on:click="onSecondClick(item,itemIndex)">
                 <span class="m3-flex-1">{{ item.name }}
                   <a-spin v-if="item.loading"/>
                <span v-if="item.hasNumber"
                      class="md-label-small m3-text-label m3-margin-left-xs md-secondary-container md-on-secondary-container-text m3-padding-horizontal-s m3-border-radius-xs">小号</span>
                <span v-if="item.hasKeyword"
                      class="md-label-small m3-text-label m3-margin-left-xs md-tertiary-container md-on-tertiary-container-text m3-padding-horizontal-s m3-border-radius-xs">关键词</span>
                <span v-if="item.hasTemplate"
                      class="md-label-small m3-text-label m3-margin-left-xs md-tertiary-container md-on-tertiary-container-text m3-padding-horizontal-s m3-border-radius-xs">话术</span>
              </span>
                <span v-if="canEdit"
                      class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                      v-on:click.stop="modifyIndustry(item,itemIndex)">编辑</span>
                <span v-if="canEditKey"
                      class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                      v-on:click.stop="editKeyDialogOpen(item)">
                  <EditOutlined class="m3-margin-horizontal-xs md-on-primary-container-text"/>关键词组</span>
                <span v-if="canDelete"
                      class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                      v-on:click.stop="deleteIndustry(item.id)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="m3-flex-3 m3-height-full">
        <div v-show="secondIndustryId"
             class="m3-height-full md-surface m3-border-radius-m m3-padding-m m3-margin-horizontal-m">
          <div
              class="md-primary md-on-primary-text m3-margin-s m3-padding-s m3-border-radius-s m3-text-center m3-view-click"
              :style="{minWidth:'80px',maxWidth:'150px'}"
              v-on:click="addIndustry(secondIndustryId)">
            新增
          </div>
          <div class="m3-width-full m3-border-bottom-variant"></div>

          <div class="m3-height-90 m3-width-full" :style="{overflow:'scroll',}">
            <div class="m3-width-full m3-margin-vertical-xs m3-padding-s md-on-surface-text md-body-medium"
                 v-for="(item,itemIndex) in thirdIndustryList" :key="'third'+itemIndex">
              <div class="m3-flex m3-justify-content-between">
                <span class="m3-flex-1">{{ item.name }}
                <span v-if="item.hasNumber"
                      class="md-label-small m3-text-label m3-margin-left-xs md-secondary-container md-on-secondary-container-text m3-padding-horizontal-s m3-border-radius-xs">小号</span>
                <span v-if="item.hasKeyword"
                      class="md-label-small m3-text-label m3-margin-left-xs md-tertiary-container md-on-tertiary-container-text m3-padding-horizontal-s m3-border-radius-xs">关键词</span>
                  <span v-if="item.hasTemplate"
                        class="md-label-small m3-text-label m3-margin-left-xs md-tertiary-container md-on-tertiary-container-text m3-padding-horizontal-s m3-border-radius-xs">话术</span>
              </span>
                <span v-if="canEdit"
                      class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                      v-on:click.stop="modifyIndustry(item,itemIndex)">编辑</span>
                <span v-if="canEditKey"
                      class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                      v-on:click.stop="editKeyDialogOpen(item)">
                  <EditOutlined class="m3-margin-horizontal-xs md-on-primary-container-text"/>关键词组</span>
                <span v-if="canDelete"
                      class="m3-border-radius-xs md-primary-container md-on-primary-container-text m3-padding-horizontal-s m3-padding-vertical-xs m3-margin-left-s m3-view-click"
                      v-on:click.stop="deleteIndustry(item.id)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!--  新增、编辑行业  -->
  <add-industry-dialog v-model:dialog-visible="editDialogVisible"
                       :industry="editIndustry"
                       @confirm="editDialogConfirm"/>

  <!--  新增、编辑行业关键词组  -->
  <add-industry-key-dialog v-model:dialog-visible="editKeyDialogVisible"
                           :industry="editKeyIndustry"
                           @confirm="editKeyDialogConfirm"/>
</template>

<script>
import {computed, onMounted, reactive, ref, toRaw} from "vue";
import dictionary from "@/api/system/dictionary";
import industryRequest, {industryAdd, industryDelete, industryListByPid} from "@/api/settings/industry";
import {ElMessage} from "element-plus";
import AddIndustryDialog from "@/views/settings/industry-line/addIndustryDialog.vue";
import {message, Modal} from "ant-design-vue";
import {EditOutlined} from '@ant-design/icons-vue';
import AddIndustryKeyDialog from "@/views/settings/industry-line/addIndustryKeyDialog.vue";

export default {
  name: "IndustryLineList",
  components: {AddIndustryKeyDialog, AddIndustryDialog, EditOutlined},
  setup(props, context) {

    let canEdit = ref(true);
    let canDelete = ref(false);
    let canEditKey = ref(true);

    let editDialogVisible = ref(false);
    let editKeyDialogVisible = ref(false);
    let editIndustry = reactive({
      id: '',
      pid: '',
      name: '',
      amountId: null,
    });
    let editKeyIndustry = reactive({
      id: '',
      pid: '',
      name: '',
      amountId: null,
    });
    let addIndustry = (pid) => {
      let industry = getIndustryFromTree(industryList.value, 'id', pid);
      editIndustry.amountId = industry?.amountId;

      editIndustry.pid = pid;
      editIndustry.id = '';
      editIndustry.name = '';
      editIndustry.template = '';

      editDialogVisible.value = true;
    }
    let modifyIndustry = (industry, industryIndex) => {
      editIndustry.amountId = industry?.amountId;
      editIndustry.pid = industry?.pid;
      editIndustry.id = industry?.id;
      editIndustry.name = industry?.name;
      editIndustry.template = industry?.template;

      editDialogVisible.value = true;
    }
    let editDialogConfirm = async (industry) => {
      if (industry) {
        //本地数据添加上新建的industry
        //如果是编辑，需要先检查是否已有，如果已有该行业，只需要修改，不需要增加
        let currentIndustry = getIndustryFromTree(industryList.value, 'id', editIndustry.id);
        console.log('editDialogConfirm', industry, currentIndustry)
        if (currentIndustry) {
          currentIndustry.amountId = industry.amountId || currentIndustry.amountId;
          currentIndustry.name = industry.name;
          currentIndustry.template = industry.template;
          currentIndustry.hasTemplate = null != industry.template && industry.template.length > 0;
          currentIndustry.pid = industry.pid || currentIndustry.pid;
        } else {
          if (industry.pid == 0) {
            //一级服务商
            industryList.value.splice(industryList.value.length, 0, {
              amountId: industry.amountId,
              name: industry.name,
              id: industry.id,
              pid: industry.pid,
              template: industry.template,
              hasTemplate: null != industry.template && industry.template.length > 0,
            })
          } else {
            let parentIndustry = getIndustryFromTree(industryList.value, 'id', editIndustry.pid);
            if (parentIndustry) {
              if (!parentIndustry.children) {
                parentIndustry.children = [];
              }
              parentIndustry.children.splice(parentIndustry.children.length, 0, {
                amountId: industry.amountId || parentIndustry.amountId,
                name: industry.name,
                id: industry.id,
                pid: industry.pid,
                template: industry.template,
                hasTemplate: null != industry.template && industry.template.length > 0,
              })
            }
          }
        }

      }
    }

    let editKeyDialogOpen = (industry) => {
      editKeyIndustry.amountId = industry?.amountId;
      editKeyIndustry.pid = industry?.pid;
      editKeyIndustry.id = industry?.id;
      editKeyIndustry.name = industry?.name;
      editKeyIndustry.template = industry?.template;

      editKeyDialogVisible.value = true;
    }
    let editKeyDialogConfirm = async (hasKeyword) => {
      if (hasKeyword) {
        let currentIndustry = getIndustryFromTree(industryList.value, 'id', editKeyIndustry.id);
        if (currentIndustry) {
          currentIndustry.hasKeyword = true;
          // editKeyIndustry.hasKeyword = true;
        }
      }
    }

    let industryList = ref([]);

    let getIndustryTree = (data, returnData) => {
      if (!data) return returnData;
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v.children?.length > 0) {
            returnData?.push({
              amountId: v.amountId,//线路
              name: v.name,
              id: v.id,
              pid: v.pid,
              hasNumber: v.hasNumber,
              hasKeyword: v.hasKeyword,
              hasTemplate: v.hasTemplate,
              template: v.template,
              children: [],
            })
          } else {
            returnData?.push({
              amountId: v.amountId,
              name: v.name,
              id: v.id,
              pid: v.pid,
              hasNumber: v.hasNumber,
              hasKeyword: v.hasKeyword,
              hasTemplate: v.hasTemplate,
              template: v.template,
            })
          }

          if (v.children) {
            getIndustryTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      return returnData;
    }
    let getIndustryFromTree = (tree, prop, id) => {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i][prop] == id) {
          return tree[i];
        }
        if (tree[i].children && tree[i].children.length > 0) {
          let find = getIndustryFromTree(tree[i].children, prop, id);
          if (find) {
            return find;
          }
        }
      }
      return null;
    }

    let getIndustryList = async () => {
      //获取全部行业列表，数据量太大，另外参考getIndustryListByPid方法，重新写的接口，只在行业配置这里使用
      let res = await dictionary.industry({});
      let arr = [];
      industryList.value = getIndustryTree(res?.data, arr);
    }

    let addIndustryList = (pid, list) => {
      if (list && list.length > 0) {
        let parentIndustry = getIndustryFromTree(industryList.value, 'id', pid);
        // console.log('addIndustryList ', pid, parentIndustry, list)
        if (parentIndustry) {
          if (!parentIndustry.children) {
            parentIndustry.children = [];
          }
          parentIndustry.children.splice(parentIndustry.children.length, 0, ...list);
        } else if (pid == 0) {
          industryList.value.splice(industryList.value.length, 0, ...list);
        }
      }
    }

    let getIndustryListByPid = async (pid) => {
      //根据pid获取行业列表，完成后添加到industryList数据中
      try {
        let res = await industryRequest.industryListByPid({pid: pid});
        // console.log('getIndustryListByPid res', res)
        let arr = [];
        let list = getIndustryTree(res?.data, arr);
        return list;
      } catch (e) {
        console.log('getIndustryListByPid err', e.message || e.msg);
        return [];
      }
    }

    let firstIndustryList = computed(() => {
      return industryList.value;
    })
    let onFirstClick = async (industry, industryIndex) => {
      if (!industry.children) {
        //如果子行业列表没有，则获取一次
        industry.loading = true;
        let list = await getIndustryListByPid(industry.id);
        addIndustryList(industry.id, list);
        industry.loading = false;
      }

      firstIndustryId.value = industry.id;
      secondIndustryId.value = '';
    }
    let onSecondClick = async (industry, industryIndex) => {
      // console.log('onSecondClick industry', industry);
      if (!industry.children) {
        //如果子行业列表没有，则获取一次
        industry.loading = true;
        let list = await getIndustryListByPid(industry.id);
        addIndustryList(industry.id, list);
        industry.loading = false;
      }
      secondIndustryId.value = industry.id;
    }

    let firstIndustryId = ref();
    let secondIndustryList = computed(() => {
      let find = industryList.value?.find(industry => industry.id == firstIndustryId.value);
      // console.log('secondIndustryList', find);
      return find?.children || [];
    })

    let secondIndustryId = ref();
    let thirdIndustryList = computed(() => {
      let find = secondIndustryList.value?.find(industry => industry.id == secondIndustryId.value);
      // console.log('thirdIndustryList', find);
      return find?.children || [];
    })


    let deleteIndustry = async (id) => {
      Modal.confirm({
        title: () => '确认删除？',
        content: () => '删除后不可恢复',
        onOk() {
          let industry = getIndustryFromTree(industryList.value, 'id', id);
          if (industry) {
            if (industry.children && industry.children.length > 0) {
              message.error('请先删除子行业')
              return;
            }
            industryDelete({id: id}).then(res => {
              if (res.code == 200) {
                if (industry.pid == 0) {
                  //一级服务商
                  let findIndex = industryList.value.findIndex(item => item.id == industry.id);
                  if (findIndex != -1) {
                    industryList.value.splice(findIndex, 1);
                  }
                } else {
                  let parentIndustry = getIndustryFromTree(industryList.value, 'id', industry.pid);
                  let findIndex = parentIndustry?.children?.findIndex(item => item.id == industry.id);
                  if (findIndex != -1) {
                    parentIndustry.children.splice(findIndex, 1);
                  }
                }

                message.success('删除成功');
              } else {
                message.error(res.message || res.msg)
              }
            }).catch(err => {
              message.error(err.message || err.msg)
            })
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {
        },
      });

    }

    onMounted(async () => {
      // getIndustryList();

      let list = await getIndustryListByPid(0);
      addIndustryList(0, list);
    })
    return {
      editIndustry, editDialogVisible,
      addIndustry, modifyIndustry, editDialogConfirm,
      editKeyIndustry, editKeyDialogVisible,
      editKeyDialogOpen, editKeyDialogConfirm,
      industryList, canEdit, canDelete, canEditKey,
      firstIndustryList, secondIndustryList, thirdIndustryList,
      firstIndustryId, secondIndustryId,
      onFirstClick, onSecondClick,
      deleteIndustry,

    }
  }
}
</script>

<style scoped>

</style>